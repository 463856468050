@import url("./fonts/stylesheet.css");

:root {
  --color-primary: #6200ea;
  --color-secondary: #676b7e;
  --color-grey: #f5f5f5;
  --color-light-grey: #ecedf0;
  --color-blue-grey: #f8f9fa;
  --color-blue-grey-2: #ecedf0;
  --color-blue-grey-3: #dfe0e6;
  --color-blue-grey-6: #afb2c0;
  --color-blue-grey-7: #9b9fb1;
  --color-blue-grey-8: #83889e;
  --color-blue-grey-9: #676b7e;
  --color-blue-grey-10: #3c3e49;
  --color-purple-1: #f3ebff;
  --color-purple-2: #b388ff;
  --color-green-2: #b9f6ca;
  --color-green-5: #00d659;
  --bs-body-color: #3c3e49;
  --bs-body-bg: #f8f9fa !important;
  --color-dark-grey: #8b8f95;
  --color-dark-grey-2: #757575;
  --color-light-grey-2: #d9d9d9;
  --color-light-grey-3: #dfdfdf;
  --color-light-grey-4: #f5f5f5;
  --color-pink: #fc5185;
  --color-cyan: #3fc1cb;
  --button-primary: #324688;
  --button-text-primary: #ffffff;
  --bs-body-bg: #f8f9fa;
  --color-light-cyan: #61d0d8;
  --color-ButtonBackRegular :  #324688;
}

* {
  font-family: "Rubik";
  font-display: swap;
}

legend {
  float: unset;
}

/* utils */

/* color */

.text-purple {
  color: var(--color-primary) !important;
}

.text-secondary {
  color: var(--color-primary) !important;
}

/* blue grey  */
.text-blue-grey-6 {
  color: var(--color-blue-grey-6) !important;
}
.text-blue-grey-7 {
  color: var(--color-blue-grey-7) !important;
}
.text-blue-grey-8 {
  color: var(--color-blue-grey-8) !important;
}
.text-blue-grey-10 {
  color: var(--color-blue-grey-10) !important;
}

/* font size  */

.f-10 {
  font-size: 0.625rem !important;
}

.f-12 {
  font-size: 0.75rem !important;
}

.f-14 {
  font-size: 0.875rem !important;
}

.f-18 {
  font-size: 1.125rem !important;
}

/* margin  */

.mb-64 {
  margin-bottom: 4rem !important;
}
.mt-64 {
  margin-top: 4rem !important;
}

.my-64 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}
.gx-64 {
  --bs-gutter-x: 4rem;
}
.gx-109 {
  --bs-gutter-x: 6.8125rem;
}

@media (min-width: 0px) {
  .container {
    max-width: 100% !important;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) {
  .gx-md-64 {
    --bs-gutter-x: 4rem;
  }
  .gx-md-109 {
    --bs-gutter-x: 6.8125rem;
  }
  .gx-md-40 {
    --bs-gutter-x: 2.5rem;
  }
}

@media (min-width: 992px) {
  .gx-lg-64 {
    --bs-gutter-x: 4rem;
  }
  .gx-lg-109 {
    --bs-gutter-x: 6.8125rem;
  }
  .gx-lg-40 {
    --bs-gutter-x: 2.5rem;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1600px !important;
  }
}

@media (min-width: 1300px) {
  .container {
    max-width: 1312px !important;
  }
  .yt-head-hide-srch-col {
    position: relative;
    left: 22.5% !important;
  }
}

@media (min-width: 400px) {
  .carousel-responsive-container .rec-carousel-wrapper {
    margin-left: -12px;
    margin-right: -12px;
    width: calc(100% + 24px);
  }
}

@media (min-width: 0px) and (max-width: 400px) {
  .carousel-responsive-container {
    max-width: 100% !important;
    padding: 0px;
  }
}

/* Card  */

.card--reset {
  border-color: var(--color-blue-grey-3) !important;
  border-radius: 3px !important;
}

iframe {
  display: none;
}
