.rbc-time-content{
    border-left: none;
    border-right: 1px solid #ECEDF0;
  }
  .rbc-event.rbc-selected {
    box-shadow: none !important;
    max-width: 106px;
    border: 1px solid transparent !important;
    width: fit-content !important;
    color: transparent;
    height: 0px !important;
  
  }
.rbc-event .rbc-selected{
  width: fit-content !important;
}
.rbc-header {
    text-align: center;
    padding: 10px 0;
    background-color: #f9f9f9;
    border: none; 
    display: flex;
    flex-direction: column;
  }
  
  
  .rbc-day-slot .rbc-time-column .rbc-now .rbc-today{
    background-color: "#F8F9FA";
  }
  
  .rbc-header + .rbc-header {
    border-left: none; 
  }
  .rbc-time-view{
    border: none;
    padding: 1px 17px;
  }
  .rbc-time-header-content{
    border-left:none;
    width:"723px";
  }
  
  .rbc-header .day {
    font-size: 12px;
    color: #000000;
    font-family:Rubik;
    font-weight: 400;
    line-height: 24px;
  }
 
  .rbc-header .date {
    font-size: 21px;
    font-weight: 500;
    line-height: 24px;
    color: #000000;
    font-family:"Rubik";
   }
  
  .rbc-header + .rbc-today  {
    background-color: #e9e6e9;
    color: white;
  }
  .rbc-header + .rbc-today  .rbc-button-link{
    border-radius: 101px;
    width: 52px;
    background-color: #6200EA;
    color: #FFFFFF;
  }
  .rbc-today .rbc-button-link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 53px;
    height: 53px; 
    background-color:#6200EA !important;
    border-radius: 50%;
    margin: auto;
}

.rbc-today .rbc-button-link div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white; 
}

.rbc-today .rbc-button-link .day{
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  font-family: "Rubik";
}
.rbc-today .rbc-button-link .date {
  font-weight: 500;
  font-size: 21px;
  line-height: 24px;
  font-family: "Rubik";
}
  
  .rbc-header .rbc-current .date {
    background-color:#6200EA;
    color: #fff;
    border-radius: 50%;
    padding: 4px 8px;
  }
  
  .rbc-header .rbc-current .day {
    color: #6200EA;
  }
  
  .rbc-toolbar {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }
  
  .rbc-toolbar button {
    margin: 0 5px;
  }

  
  .react-calendar__tile--highlight {
    background-color: rgb(221, 23, 188);
  }
  
  .rbc-allday-cell .rbc-row-bg .rbc-day-bg,
  .rbc-allday-cell .rbc-row-bg .rbc-day-bg.rbc-today,
  .rbc-row-content .rbc-row {
    border-top: none;
    border-left: none;
    border-right: none;
    margin-bottom: 50px;
  }
  
  .rbc-allday-cell {
    display: none;
  }
  .react-calendar__tile--highlight {
    background-color: #6200EA !important;
    color: white !important;
  }

  .react-calendar__navigation button:enabled:hover, .react-calendar__navigation button:enabled:focus {
    background-color: white;
  }

  .react-calendar__tile {
    max-height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
  } 

  .rbc-time-content .rbc-today, 
  .rbc-allday-cell .rbc-today {
      background-color: #F8F9FA !important;
  }
  .rbc-header.rbc-today {
    background-color: transparent !important;
  }

  .rbc-day-slot .rbc-event {
    overflow: visible; 
    background-color: transparent; 
    border: none; 
    box-shadow: none; 
    color:  #F8F9FA; 
    padding: 0; 
    margin: 0;
}
.rbc-time-header-cell-single-day{
  display: block !important;
}
.rbc-time-header.rbc-overflowing{
  border-right: none !important;
}

.rbc-day-slot .rbc-event-content{
  z-index: 1;
  width: 15%;
}
.rbc-day-slot .rbc-event-label{
  display: none !important;
}
.rbc-event .rbc-selected{
  width:30px !important;
}
.rbc-current-time-indicator {
  background-color: rgba(255, 171, 0, 1) ;
  z-index: 0;
  
}

.rbc-day-slot .rbc-event.custom-event {
    background-color: green;
    color: white;
}
.rbc-toolbar-label{
display: block;
}
.MuiSelect-nativeInput {
  opacity: 1 !important;
  position: relative !important;
  pointer-events: auto !important;
}

.rbc-timeslot-group {
  min-height: 60px !important;
}

.custom-select:focus {
  outline: none; 
  border-color: #000; 
}
.rbc-event:focus {
  outline: none;
}





