.admin-layout {
    display: flex;
    height: 100%;
    overflow-y: auto;
}

.admin-layout__sidebar {
    width: 236px;
    border-right: 2px solid var(--color-light-grey);
    display: flex;
    flex-direction: column;
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
}

.setup-guide-menu {
    width: 276px;
}
.admin-layout__main {
    flex-grow: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    margin-left: 236px;
}

.admin-layout__sidebar,
.admin-layout__main {
    transition: all 0.3s ease;
}

.admin-layout--hide-nav .admin-layout__sidebar {
    transform: translateX(-100%);
}

.admin-layout--hide-nav .admin-layout__main {
    margin-left: 0px;
}

.admin-layout__nav {
    width: 100%;
    border-bottom: 2px solid var(--color-light-grey);
    padding: 24px 20px;
}
.admin-layout__hamburger {
    color: currentColor !important;
    text-decoration: none;
}

.admin-layout__brand-logo {
    display: flex;
    align-items: center;
    padding-left: 24px;
    border-bottom: 2px solid var(--color-light-grey);
}

.admin-layout__link-container {
    flex-grow: 1;
    overflow: hidden;
}

.admin-layout__sidebar,
.admin-layout__nav {
    background-color: white;
}

.admin-layout__brand-logo,
.admin-layout__nav {
    min-height: 68px;
    max-height: 68px;
}

.admin-layout__content {
    flex-grow: 1;
    overflow-y: scroll;
    background-color: #f8f9fa;
    position: relative;
    height: 100%;
}

.admin-layout__content::-webkit-scrollbar {
    width: 0px;
}

.me-4 .textBox{
    align-items: center !important;
}

.feedbackButton {
    cursor: pointer;
    width: fit-content;
    display: flex;
    align-items: center;
    gap: 6px;
    background-color: #5500CC;
    border-radius: 100px;
    padding: 11px 26px;
    position: fixed;
    bottom: 30px;
    right: 24px;
}
.feedbackIcon {
    width: 18px;
    height: 18px;
}
.feedbackTxt {
    color: #fff;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    font-family: Inter;
}