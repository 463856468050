.snackbar-message-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 95vw;
}

.snackbar-logo {
    height: 20px;
    width: 160px;
    margin: 4px;
}

.snackbar-message {
    width: 100%;
    display: flex;
    text-overflow: ellipsis;
    justify-content: center;
}

.snackbar-message-icon {
    width: 24px;
    height: 24px;
    margin: 0 8px;
}

.snackbar-message-icon-message {
    font-family: 'Rubik';
    font-display: swap;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    margin-right: 6px;
}

.snackbar-message-content {
    font-family: 'Rubik';
    font-display: swap;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
}