.branding-main {
    display: flex;
    flex-direction: column;
    background: #f8f9fa;
  }
  
  
  .branding-main .tabs-wrapper .MuiTab-wrapper{
    flex-direction: row !important;
  }
  
  .branding-main .header {
    width: 100%;
    padding: 40px 22px 0;
    border-bottom: 2px solid #e8e8e8;
  }
  
  .branding-main .header-title-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
  }
  
  .branding-main .header-title {
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 32px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: #3c3e49;
  }
  
  .branding-main .header-buttons {
    display: flex;
    gap: 24px;
  }
  
  .branding-main .header-buttons .bulk-upload-button {
    all: unset;
    cursor: pointer;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    text-decoration-line: underline;
    color: #3c3e49;
  }
  
  .branding-main .header-buttons .add-image-button {
    all: unset;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 500;
    padding: 10px 32px;
    background: #00d659;
    border-radius: 3px;
    font-size: 12px;
    line-height: 20px;
    color: #3c3e49;
  }
  
  .branding-main .tabs-wrapper {
    margin-top: 40px;
  }
  
  
  .branding-main .delete-items {
    position: absolute;
    top: 50px;
    left: 250px;
    cursor: pointer;
  }
  
  .branding-main .tabs-root {
    display: flex;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #83889e;
    text-transform: none;
    padding: 0;
    min-height: 30px;
  }
  
  .branding-main .tabs-indicator {
    background-color: #6200ea !important;
  }
  
  .branding-main .tabs-flex-container {
    display: flex;
    gap: 43px;
  }
  
  .branding-main .tab-label-icon {
    min-height: 28px;
    min-width: 80px;
  }
  
  .tab-icon {
    margin-bottom: 0 !important;
    margin-right: 6px !important;
  }
  
  .branding-main .tab-label-selected {
    line-height: 20px;
    color: #6200ea !important;
    font-weight: 400;
    font-size: 12px;
  }
  
  .branding-main .tab-icon-wrapper{
    margin: 0 12px 0 0 !important;
  }
  
  .branding-main .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    padding: 40px 22px;
  }
  
  
  
  .branding-main .content-header {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    gap: 16px;
  }
  
  .branding-main .search-wrapper {
    flex: 1;
  }
  
  .branding-main .search-wrapper .full-width {
    width: 100%;
  }
  
  .branding-main .search-wrapper input {
    padding: 10px 14px 10px 2px;
  }
  
  .branding-main .view-type-wrapper {
    display: flex;
  }
  
  .branding-main .view-type-wrapper>* {
    background: #fafafa;
    border-top: 1px solid #d0d2da;
    border-left: 1px solid #d0d2da;
    border-bottom: 1px solid #d0d2da;
    padding: 10px 0;
    white-space: nowrap;
  }
  
  .branding-main .view-type-wrapper>*:first-child {
    border-radius: 3px 0px 0px 3px;
  }
  
  .branding-main .view-type-wrapper>*:last-child {
    border-right: 1px solid #d0d2da;
    border-radius: 0 3px 3px 0;
  }
  
  .branding-main .view-type-indicator {
    background-color: #6200ea !important;
    display: none;
  
  }
  
  .branding-main .view-type-root {
    min-width: 120px;
  }
  
  .branding-main .view-type-selected {
    line-height: 20px;
    color: #6200ea !important;
    font-weight: 600;
  }
  
  .branding-main .view-type-text-wrapper {
    text-transform: none !important;
    display: flex;
    flex-direction: row;
    gap: 8px;
  }
  
  .branding-main .catalogue-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #ffffff;
    box-shadow: 0px 4px 4px #f5f5f5;
    border-radius: 5px;
  }
  
  .branding-main .image-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  
  .branding-main .catalogue-item .catalogue-item-footer {
    display: flex;
    flex-direction: row;
    gap: 16px;
    padding: 5px 20px;
  }
  
  .branding-main .catalogue-item .catalogue-item-name {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
  }
  
  .branding-main .catalogue-item .catalogue-item-buttons {
    height: 48px;
    display: flex;
    flex: 1;
    justify-content: flex-end;
    gap: 10px;
    white-space: nowrap;
  }
  
  .branding-main .catalogue-item .catalogue-item-edit-button {
    flex: 1;
  }
  
  .branding-main .catalogue-item .catalogue-item-delete-button {
    padding: 0;
    flex: 1;
  }
  
  
  
  
  .branding-main .edit-icon {
    padding: 0;
    flex: 3;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    text-transform: none;
    color: #364f6b;
    border: 2px solid #364f6b;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    display: flex;
    border-radius: 4px;
  }
  
  .delete-dialog .delete-dialog-title {
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 32px;
    padding: 40px 40px 24px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: #3c3e49;
  }
  
  .delete-dialog .delete-dialog-message {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    color: #3c3e49;
  }
  
  .delete-dialog .delete-dialog-cancel {
    cursor: pointer;
    text-transform: none;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    text-decoration-line: underline;
    margin-right: 34px;
    color: #3c3e49;
  }
  
  .delete-dialog .delete-dialog-ok {
    cursor: pointer;
    text-transform: none;
    background: #ff1744;
    border-radius: 3px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px 48px;
    color: white;
  }
  
  .delete-dialog .delete-dialog-ok:hover {
    background: rgb(240, 25, 73);
  }
  .header-text{
  color: var(--blue-gray-8, #83889E);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Rubik', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  }

.color-picker-on-drawer-marketing .chrome-picker {
  position: absolute;
  bottom: -80px;
  left: 145px;
}

#font-family-header {
  font-family: var(--header-text-font) !important;
}